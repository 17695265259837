.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.title {
  padding-top: 4rem;
}
.tablebar {
  padding-bottom: 2rem;
}
.input-label{
  color:#212529;
}
.input-col {
  margin-left: 20px;
  margin-right: 20px;
}
.modal-content {
  background-color: #212529 !important;
}
.modal-title {
  color: white;
}